import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  font-family: Nunito,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
  .divCheck {
    display: flex;
    align-items: center;
    h6 {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
      color: rgb(170, 170, 170);
      margin-right: 6px;
    }
  }

  }
  .label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 22px; //do not overlap icon
    bottom: 0px; //maximize container height so label wont be cut horizontaly
}

.label-shrinked {
    right: unset; //show complete label if shrinked
}
`;

export const ButtonStyled = styled.button`
  margin-top: 40px;
  margin-left: 5px;
  width: 250px;
  padding: 10px;
  border: 0 none;
  background-color: #00acc1;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  position:relative;
  cursor: pointer;
  &:hover {
    border-radius: 20px;
    opacity: 0.7;

  }


`;
export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index="4";
  
.btnAdd{
  margin-top: 40px;
  margin-left: 5px;
  width: 250px;
  padding: 10px;
  border: 0 none;
  background-color: #00acc1;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  position:relative;
  cursor: pointer;
  &:hover {
    border-radius: 20px;
    opacity: 0.7;

  }
}


`;

export const Content = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 30px;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
`;